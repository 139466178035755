import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { appRouter } from './app.router';
import { AppComponent } from './app.component';
import { SharedModule } from "./shared/shared.module";
import { HttpClientModule } from "@angular/common/http";
import { AuthGuardService, AuthenticateService } from './shared';
import { CookieService } from 'ngx-cookie-service';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    SharedModule,
    appRouter,
    HttpClientModule
  ],
providers: [AuthGuardService, AuthenticateService, CookieService],
  bootstrap: [AppComponent]
})
export class AppModule { }
