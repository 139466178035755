import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from './../../environments/environment';
import { ContactUs, CommonModelResponse } from './../models';
import { Observable } from "rxjs";
@Injectable()
export class ContactUsService {
    constructor(private http: HttpClient) { }

    public saveContactDetails(conatctUs: ContactUs): Observable<string> {
        return this.http.post<string>(environment.apiUrl + 'api/Contactus', conatctUs).pipe(data => data);
    }

    public sendOTP(conatctUs: ContactUs): Observable<string> {
        return this.http.post<string>(environment.apiUrl + 'api/SendOTPForDownloadBrochure', {
            Email: conatctUs.Email,
            MobileNumber: conatctUs.MobileNumber
        }).pipe(data => data);
    }

    public downloadFile(url: string): Observable<any> {
        let headers = new HttpHeaders();
        headers = headers.set('Accept', 'application/pdf');
        return this.http.get(url, { headers: headers, responseType: 'blob' });
    }


}

