export class ContactUs {
    public Name: string;
    public Email: string;
    public Subject: string;
    public Message: string;
    public PropertyID: number;
    public MobileNumber: number;
    public Source: string;
    public SubSource: string;
    public HowdoyouKnow: string;
    public ReferedBy: string;
    public CallFeedBack: string;
    public Sqft:string;
}