export class CustomerDetails {

    public CustomerId: number;
    public Name: string;
    public Surname: string
    public S_W_DoF: string
    public Nationality: string;
    public DateOfBirth: any;
    public Age: number;
    public ProfessionalService: string
    public ResidentialStatus: string
    public PANNo: string
    public PermanentAddress: string
    public Address1: string
    public Address2: string
    public TelephoneNo: string
    public MobileNo: string
    public Designation: string
    public OfficeNameAndAddress: string
    public EmailId: string
    public Name1: string
    public Surname1: string
    public S_W_DoF1: string
    public Nationality1: string
    public DateOfBirth1: any;
    public Age1: number
    public ProfessionalService1: string
    public ResidentialStatus1: string
    public PANNo1: string
    public PermanentAddress1: string
    public Address_1: string
    public Address_2: string
    public TelephoneNo1: string
    public MobileNo1: string
    public Designation1: string
    public OfficeNameAndAddress1: string
    public EmailId1: string
    public TotalAmount: number
    public Remarks: string;
    public AnnualIncome: string;
    public AnnualIncome1: string;
    public AadharNumber1: string;
    public AadharNumber: string;
    public PassportNumber: string;
    public Status: boolean;
    public CountryID1:string;
    public CountryID:string;
    public CountryCode:string;
    public CountryCode1:string;
}






