import { Component, OnInit, AfterViewInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from './../../../environments/environment';
import { ContactUsService } from "../../contact-us/contact-us.service";
import { ContactUs, LoginModel, User } from "../../models";
import { CookieService } from 'ngx-cookie-service';
import { AuthenticateService } from '../services';
import { Router } from '@angular/router';

declare var $: any;
declare var download: any;

@Component({
    selector: 'cmp-header',
    templateUrl: './header.component.html',
    styleUrls: ['../../contact-us/contact-us.component.css']
})
export class HeaderComponent implements OnInit, AfterViewInit {

    ngAfterViewInit(): void {

        if ($('#sticker').length) {
            $("#sticker").sticky({ topSpacing: 0 });
        }

        // $(document).click(function (event) {

        //     var clickover = $(event.target);

        //     var _opened = $('#collapseAnchor').attr('aria-expanded');
        //     if (_opened = 'true' && clickover.context.className != 'first tollfree') {

        //         $('#collapseAnchor').attr('aria-expanded', 'false')
        //         $('#collapseAnchor').attr('class', 'first tollfree collapsed')
        //         $('#collapseExample').attr('class', 'collapse clogin-open')
        //         $('#collapseExample').attr('aria-expanded', 'false')
        //         $('#collapseExample').attr('style', 'height: 0px;');
        //     }
        // });
    }

    public contactUs: ContactUs = new ContactUs();
    public contactPopup: boolean = true;
    public verifyOtpPopup: boolean = false;
    public submitted: boolean = false;
    public clickPerformed: boolean = false;
    public validOtp: boolean = false;
    public isVerifyClickPerformed = false;
    fileUrl;
    public loginModel: LoginModel;
    public loggedUser: User;
    public isValid: Boolean = false;
    public isValidUsername: Boolean = false;
    public isValidPassowrd: Boolean = false;
    public User: User;
    private username: String = '';
    private forgotemail: string;
    private UserID: number;
    private Forgotpassword: boolean;
    private validfemail: boolean = true;
    private isvalidfemailexsists: boolean = false;
    private validfemailformat: boolean = false;
    private forgoremailsucess: boolean = false;
    public siteKey = environment.captchaKey;
    public isCaptcha = false;
    public captchaError = false;
    public size = 'compact';
    public size1 = 'normal';
    public isDownloadCaptcha = false;
    public isDownloadCaptchaError = false;

    constructor(private router: Router, private contactUsService: ContactUsService, private sanitizer: DomSanitizer, private _cookieService: CookieService, private authService: AuthenticateService) { }

    public ngOnInit() {



        const retrievedObject: User = this.authService.getUserInfo();
        if (retrievedObject != null) {
            this.username = retrievedObject.FirstName;
            this.UserID = retrievedObject.UserID;
        } else {
            this.UserID = 0;
        }
        this.Forgotpassword = true;
        this.loginModel = new LoginModel();
        // this.checkCookie();

    }

    private onBroucherSubmit(isFormValid: boolean): void {

        if (isFormValid && !this.clickPerformed) {

            // this.isDownloadCaptchaError = false;
            // this.clickPerformed = true;
            // this.contactUs.Subject = 'Customer Enquiry';
            // this.contactUs.PropertyID = 4;
            // this.contactUs.Source = 'Brouchure';
            // this.contactUs.SubSource = "";
            // this.contactUs.HowdoyouKnow = "ProjectWebsites";
            // this.contactUs.ReferedBy = "";
            // this.contactUs.CallFeedBack = "";
            // this.contactUs.Message = this.contactUs.Message ? this.contactUs.Message : "";
            // this.contactUsService.sendOTP(this.contactUs).subscribe(resp => {
            //     this.clickPerformed = false;
            //     this.contactPopup = false;
            //     this.verifyOtpPopup = true;
            //     localStorage.setItem('otpValue', JSON.parse(resp));
            // });
          
            this.contactUs.PropertyID = 4;
            this.contactUsService.saveContactDetails(this.contactUs).subscribe(resp => {
                this.contactUsService.downloadFile(environment.webUrl + "assets/img/broucher/HAPPYNEST-BROCHURE.pdf").subscribe(respdata => {
                    var url = window.URL.createObjectURL(respdata);
                    this.fileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
                    var link = document.createElement('a');
                    link.href = this.fileUrl.changingThisBreaksApplicationSecurity;
                    link.download = "HAPPYNEST-BROCHURE.pdf";
                    link.click();
                    $(".close").trigger("click");
                    this.contactPopup = true;
                    this.verifyOtpPopup = false;
                    this.submitted = false;
                    this.isVerifyClickPerformed = false;
                    this.contactUs = new ContactUs();
                });

            });

        }
    }

    // private OnVerifyOTP(otp: string): void {
    //     this.submitted = true;
    //     if (!this.isVerifyClickPerformed) {
    //         this.isVerifyClickPerformed = true;
    //         if (otp && otp.length == 6 && parseInt(otp) == parseInt(localStorage.getItem('otpValue'))) {
    //             this.validOtp = true;
    //             this.contactUsService.saveContactDetails(this.contactUs).subscribe(resp => {
    //                 this.contactUsService.downloadFile(environment.webUrl + "assets/img/broucher/HAPPYNEST-BROCHURE.pdf").subscribe(respdata=> {
    //                     var url = window.URL.createObjectURL(respdata);
    //                     this.fileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    //                     var link = document.createElement('a');
    //                     link.href = this.fileUrl.changingThisBreaksApplicationSecurity;
    //                     link.download = "HAPPYNEST-BROCHURE.pdf";
    //                     link.click();
    //                     $(".close").trigger("click");
    //                     this.contactPopup = true;
    //                     this.verifyOtpPopup = false;
    //                     this.submitted = false;
    //                     this.isVerifyClickPerformed = false;
    //                     this.contactUs = new ContactUs();
    //                 });

    //             });
    //         } else {
    //             this.validOtp = false;
    //             this.isVerifyClickPerformed = false;
    //         }
    //     }
    // }

    

    public handleSuccess(event): void {
        this.isCaptcha = true;
    }

    public downloadBroucherSuccess(event): void {
        this.isDownloadCaptcha = true;
    }

    public clearDownloadForm(): void {

        this.contactUs = new ContactUs();
        this.isDownloadCaptchaError = false;
        this.isDownloadCaptcha = false;
    }

}
