import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import {  CommonModelResponse, User, UserLogs } from '../../models';
@Injectable()
export class AuthenticateService {
    public isloggedIn = false;
    private redirectUrl = '';
    private loginUrl = '/';
    constructor(private http: HttpClient) {
        if (localStorage.getItem('userDetails') && localStorage.getItem('userDetails') !== '') {
            this.isloggedIn = true;
        }
    }

    public authenticateUser(Email: string, Password: string): Observable<CommonModelResponse<User>> {
        const body: any = { Email: Email, Password: Password };
        return this.http.post<CommonModelResponse<User>>(environment.apiUrl + 'api/FourSightLogin', body,
            {
                headers: new HttpHeaders().set('Content-Type', 'application/json')
            }).pipe();
    }

    public isUserLoggedIn(): boolean {
        return this.isloggedIn;       
    }

    public getUserInfo(): User {
        
        if (localStorage.getItem('userDetails') && localStorage.getItem('userDetails') !== '') {
            const data = JSON.parse(localStorage.getItem('userDetails'));
            return data;
        }
        return null;
    }

    public logoutUser(): void {
        const user: User = this.getUserInfo();
        // this.updateTokenStatus(user.UserID, localStorage.getItem('fcmToken'), false).subscribe();
        localStorage.clear();
        this.isloggedIn = false;
    }

    public getRedirectUrl(): string {
        return this.redirectUrl;
    }
    public setRedirectUrl(url: string): void {
        this.redirectUrl = url;
    }
    public getLoginUrl(): string {
        return this.loginUrl;
    }



    public updateTokenStatus(userID, token, status): Observable<CommonModelResponse<string>> {

        return this.http.post<CommonModelResponse<string>>(environment.apiUrl + 'api/UpdateTokenStatus?UserID=' + userID
            + '&Token=' + token + '&Status=' + status,
            {
                headers: new HttpHeaders().set('Content-Type', 'application/json')
            }).pipe(data => data);
    }

    public getUserLogs(UserID: number): Observable<CommonModelResponse<UserLogs>> {
       
        return this.http.get<CommonModelResponse<UserLogs>>(environment.apiUrl + "api/UserLogs?$filter=UserId eq " +
            UserID 

        ).pipe(data => data);   
    }

    public SaveUserLogs(data:UserLogs) :Observable<CommonModelResponse<UserLogs>> {
        debugger
        const body: any = { "UserLog": data };
       
        return this.http.post<CommonModelResponse<UserLogs>>(environment.apiUrl + 'api/SaveUpdateUserLogs', body,
        {
        
            headers: new HttpHeaders().set('Content-Type', 'application/json')
        }).pipe(data => data);

      
    }
}
