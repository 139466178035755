export class Feeds {
    NewsFeedID: number;
    Title: string;
    Subject: string;
    Description: string;
    CreatedOn: string;
    DocumentPaths: Array<string>;
}

export class NewsFeedTags {
    TagId: number;
    TagName: string;
    isSelected: boolean;
}
