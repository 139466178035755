export class BookingDetails {
    public CustomerId: number;
    public PaymentId: number;
    public order_id: string;
    public bank_ref_no: string;
    public amount: number;
    public FlatId: number;
    public FlatNo: string;
    public Face: string;
    public Size: string;
    public NoOfBeds: number;
    public FloorId:number;
    public FloorName :  string;
    public BlockName :  string;
    public BlockTitle:  string;
    public status:number;
    public Type:string;

}