export class UserLogs {
      LogId :number;
     UserId :number;
      InTime :Date;
      OutTime:Date;
     IpAddress :string;
     Device :string;
     Browser :string;

}

