export class BlockAvailability {
    public BlockName: string;
    public Floors: Array<FloorAvailability>;
    public colspan: number;
}
export class FloorAvailability {
    public FloorName: string;
    public Flats: Array<FlatAvailability>;
}
export class FlatAvailability {
    public BlockName : string;
    public FloorName : string;
    public FlatNo : string;
    public Size : string;
    public Face : string;
    public Status : number;
    public FilterStatus: number;
    public FlatId: number;
    public ReleaseVersion: number;
}
