export class CommonListResponse<T> {
    public data: Array<T>;
    public bookingDate: Date;
    public error: string;
    public value: string;
}

export class CommonModelResponse<T> {
    public data: T;
    public bookingDate: Date;
    public error: string;
    public value: string;
}

export class CommonReturnModelResponse<T> {
    public data: T;
    public error: string;
    public value: T;
}

export class CommonReturnListResponse<T> {
    public data: T;
    public error: string;
    public value: Array<T>;
    public dataList: Array<T>;
}
