export class FlatDetails {
    public FlatId: number;
    public FloorName: string;
    public FloorId: number;
    public FlatNo: number;
    public Size: any;
    public Typeofflat: string;
    public Face: string;
    public Corner: string;
    public Price: number;
    public NoOfBalconies: number;
    public NoOfBeds: number;
    public Parking: string;
    public Image2D: string;
    public Image3D: string;
    public Status: number;
    public CustomerID: number;
    public BasicCost: any;
    public ParkingCost: number;
    public ClubHouseCost: number;
    public LegalDocumentCost: number;
    public InfrastructureCost: number;
    public EastFacingPlc: number;
    public FloorRisePlc: number;
    public ExtraCarParkingCost: number;
    public MaintenanceCharges: number;
    public CarpusFund: number;
    public FaceView: string;
    public BlockDate: any;
    public BookDate: any;
}
