import { Injectable } from "@angular/core";

@Injectable()
export class Helper {

    public getFYDate(financialYear: string): Array<string> {

        var result = new Array<string>();

        var years = financialYear.split('-');

        result.push(years[0].toString() + '-04-01');
        result.push(years[1].toString() + '-03-31');

        return result;
    }

    public getFYDateByYear(financialYear: string): Array<string> {

        var result = new Array<string>();

        var year1 = financialYear;
        var year2 = parseInt(financialYear) + 1;

        result.push(year1 + '-04-01');
        result.push(year2 + '-03-31');

        return result;
    }

    public dateFilter(type: string): Array<string> {

        var result = new Array<string>();
        var today = new Date();
        var todayString = (today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate()).toString();

        if (type === 'Day') {

            result.push(todayString);
            result.push(todayString);

        } else if (type === 'Week') {

            today.setDate(today.getDate() - 7);
            result.push((today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate()).toString());
            result.push(todayString);

        } else if (type === 'Month') {

            today.setMonth(today.getMonth() - 1);
            result.push((today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate()).toString());
            result.push(todayString);

        } else if (type === 'Quarter') {

            today.setMonth(today.getMonth() - 3);
            result.push((today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate()).toString());
            result.push(todayString);

        } else if (type === 'Yearly') {

            today.setFullYear(today.getFullYear() - 1);
            result.push((today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate()).toString());
            result.push(todayString);

        }

        return result;
    }
} 