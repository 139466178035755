import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'cmp-footer',
  templateUrl: './footer.component.html',

})
export class FooterComponent implements OnInit {


  constructor() { }

  ngOnInit() {

    
  }
}
 
  

