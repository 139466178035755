import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { WebsocketService } from './websocket.service';

const CHAT_URL = 'wss://crmapi.happynest.live/NotifyBlockFlat';

@Injectable()
export class FlatStatusNotifierService {
	public messages: Subject<any>;

	constructor(wsService: WebsocketService) {
		this.messages = <Subject<any>>wsService
			.connect(CHAT_URL)
			.pipe(response => response);
	}
}