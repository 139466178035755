import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'cmp-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  ngOnInit(): void {
  }
  title = 'app';
  onActivate(e, outlet) {
    window.scrollTo(0, 0);
  }
}
