export class CustomerPayments {
    public FlatId: number;
    public Name: string;
    public MobileNo: string;
    public EmailId: string;
    public PANNo: string;
    public PermanentAddress: string;
    public BlockName: string;
    public FloorName: string;
    public FlatNo: string;
    public Face: string;
    public Size: string;
    public amount: number;
    public Date: Date;
    public InstallmentNo: number;
    public NoOfBeds: number;
}
