export class CustomerDocuments {

    public Document1Path: string;
    public Document2Path: string;
    public Document3Path: string;
    public Document1: string;
    public Document2: string;
    public Document3: string;
    public DocumentName: string;
    public DocumentPath: string;
    public DocumentId: number;
    public CustomerId: number;
    public DocumentStatus: number;
    public Status: boolean;

}