import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { OnlyNumber } from './directives'
import { AuthenticateService, WebsocketService, FlatStatusNotifierService } from './services';
import { Helper } from './helper';
import {
    HeaderComponent, FooterComponent
} from './layouts';
import { ContactUsService } from "../contact-us/contact-us.service";
import { NgxCaptchaModule } from 'ngx-captcha';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        HttpClientModule,
        NgxCaptchaModule
    ],
    declarations: [HeaderComponent, FooterComponent, OnlyNumber],
    providers: [ContactUsService,AuthenticateService,
        Helper,WebsocketService, FlatStatusNotifierService],
    exports: [
        CommonModule,
        FormsModule,
        RouterModule,
        FooterComponent,
        HeaderComponent,
        OnlyNumber,
        NgxCaptchaModule
    ]
})
export class SharedModule { }
