export class User {
    Result: string;
    FirstName: string;
    LastName: string;
    PhoneNumber: string;
    Email: string;
    UserID: number;
    CreatedOn: Date;
    Status: number;
    PropertyID: number;
    RoleName: string;
    LoginType:number;
    OrgId:number;
    CustomerID:number;
    Password:string;
    NewPassword: string;
    ConfirmPassword: string;

}

