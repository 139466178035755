
import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule, CanActivate } from '@angular/router';
import { AuthGuardService } from './shared';
export const router: Routes = [
    {
        path: '',
        loadChildren: './home/home.module#HomeModule'
    },
    {
        path: 'about-project',
        loadChildren: './about-project/about-project.module#AboutProjectModule'
    },
    {
        path: 'master-plan-floor-plan',
        loadChildren: './master-plan-and-floor-plan/master-plan-and-floor-plan.module#MasterPlanAndFloorPlanModule'
    },
    {
        path: 'amenities-and-specifications',
        loadChildren: './amenities-and-specifications/amenities-and-specifications.module#AmenitiesAndSpecificationsModule'
    },
    {
        path: 'gallery',
        loadChildren: './gallery/gallery.module#GalleryModule'
    },
    {
        path: 'contact-us',
        loadChildren: './contact-us/contact-us.module#ContactUsModule'
    },
    {
        path: 'privacy-policy',
        loadChildren: './privacy-policy/privacy-policy.module#PrivacyPolicyModule'
    },
    {
        path: 'terms-and-conditions',
        loadChildren: './terms-conditions/terms-conditions.module#TermsConditionsModule'
    },
    {
        path: 'frequently-asked-questions',
        loadChildren: './faq/faq.module#FaqModule'
    },
    {
        path: 'payment-terms',
        loadChildren: './price-list/price-list.module#PriceListModule'
    },
    {
        path: 'cc-avenue',
        loadChildren: './cc-avenue/cc-avenue.module#CcAvenueModule'
    },
    {
        path: 'thank-you',
        loadChildren: './thank-you/thank-you.module#ThankYouModule'
    },
    {
        path: 'sorry',
        loadChildren: './sorry/sorry.module#SorryModule'
    },
    {
        path: '',
        loadChildren: './login/login.module#LoginModule'
    },
    {
        path: 'change-password',
        loadChildren: './change-password/change-password.module#ChangePasswordModule',
        canActivate: [AuthGuardService]
    },
    {
        path: 'documents',
        loadChildren: './documents/documents.module#DocumentsModule',
    },
    {
        path: 'terms-conditions',
        loadChildren: './terms-and-conditions/terms-and-conditions.module#TermsAndConditionsModule'
    },
    {
        path: 'flat-availability',
        loadChildren: './flat-availability/flat-availability.module#FlatAvailabilityModule'
    },
    {
        path: 'interests',
        loadChildren: './intrest-form/intrest-form.module#IntrestFormModule'
    },
    {
        path: 'banklist',
        loadChildren: './banklist/banklist.module#BanklistModule'
    },
    {
        path: 'visuals',
        loadChildren: './visuals/visuals.module#VisualsModule'
    }
];

export const appRouter: ModuleWithProviders = RouterModule.forRoot(router);
